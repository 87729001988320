import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FlexCard from '../components/flexcard';
import GiftCardBackground from '../components/giftcardbackground';
import settings from '../../settings';

// images
const topbanner = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/desktop/bmw-specials-banner.jpg`;
const bmwspecialoffer = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/desktop/bmw-special-offer.jpg`;
const turnlearn = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/desktop/turn_learn.jpg`;
const groupevent = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/desktop/group_event.jpg`;
const teentime = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/desktop/teen_time.jpg`;

const SpecialOffers = () => {
  const getFirstCard = () => {
    const today = new Date();
    const futureDate = today > new Date('May 6 2021 23:59') && today < new Date('June 1 2021 00:00');
    if (futureDate) {
      return (
        <>
          <FlexCard
          rowType="row"
          image={groupevent}
          imageAlt="May days"
          header="May days"
          subheader="10% off a group event when you book by May 31st."
          text="Bring your group to the Performance Center for some fast fun. Book any Group event during the month of may, and get 10% off."
          btnText="Call 888-345-4BMW to book now"
          btnStyle="btn-blue"
          phone="tel:888-345-4269"
          >
          <p>
          Please call to book, and use code <span className="bold">21RB531GE10</span>. Offer not valid online. Offer cannot be combined with any other offer. Available only on Group Events booked by 5/31/21.
          </p>
          </FlexCard><br/>
          <FlexCard
          rowType="row"
          image={teentime}
          imageAlt="Teen time"
          header="Teen time"
          subheader="20% off One-Day Teen School in South Carolina"
          text="Spring is in full swing here. Get 20% off when you book a One-Day Teen School Class in South Carolina."
          btnText="Call 888-345-4BMW to book now"
          btnStyle="btn-blue"
          phone="tel:888-345-4269"
          >
          <p>
          Please call to book, and use code <span className="bold">21RB531SC20</span>. Offer not valid online. Offer cannot be combined with any other offer. Available only on One-Day Teen School in South Carolina. Valid on any class date, must book by 5/31/21.
          </p>
        </FlexCard><br/>
          <FlexCard
          rowType="row"
          image={turnlearn}
          imageAlt="Turn and learn"
          header="Turn and learn"
          subheader="20% Off one-day driver’s school in California"
          text="It’s time for a high performance education. Get 20% Off One-Day Driver’s School Class in California."
          btnText="Call 888-345-4BMW to book now"
          btnStyle="btn-blue"
          phone="tel:888-345-4269"
          >
          <p>
          Please call to book, and use code <span className="bold">21RB531CA20</span>. Offer not valid online. Offer cannot be combined with any other offer. Available only on One-Day Teen School in South Carolina. Valid on any class date, must book by 5/31/21.
          </p>
          </FlexCard>
        </>
      )
    } else {
      return (
        <FlexCard
        rowType="row"
        image={bmwspecialoffer}
        imageAlt="Hold your turbocharged horses"
        header="Hold your turbocharged horses"
        subheader="There are no current special offers"
        text="Follow us on social media to make sure you stay up to date on the latest BMW Performance Driving School special offers."
        btnText="Facebook"
        btnStyle="btn-blue"
        externalURL="https://www.facebook.com/bmwperformancecenter"
      >
        <a href="https://www.instagram.com/bmwperformancedrivingschools/" target="_blank" rel="noreferrer">
          <button className="btn btn-blue">Instagram</button>
        </a>
        <br/><br/>
      </FlexCard>
      )
    }
  }
  return (
    <Layout>
      <SEO title="BMW Performance Driving School | Gift Cards" description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!" />
      <div className="giftcardpage">
          <GiftCardBackground
            imageDesktop={topbanner}
            imageMobile={topbanner}
            imageAlt="BMW Gift Card Top Banner"
          />

          <section className="performance-center">
            <div className="container">
              <div className="performance-center__header">
                <h2>Special Offers</h2>
              </div>
            </div>
            {getFirstCard()}
          </section>
        </div>
    </Layout>
  );
};

export default SpecialOffers;
